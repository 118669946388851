import React from 'react';
import SEOHelmet from '../SEOHelmet';

const NCRPage:React.FC = () => {
  return (
    <div className="page-container  mt-36 mb-20">
      <SEOHelmet />
      <h1>Top Psychologist in Delhi NCR - Dr. Pratik Kumar</h1>
      <p>Dr. Pratik Kumar: Your trusted psychologist and psychiatrist serving the entire Delhi NCR region. Experience comprehensive mental health care tailored to your needs.</p>
      
      <section>
        <h2>Comprehensive Mental Health Services Across NCR</h2>
        <ul>
          <li>Depression and Anxiety Treatment</li>
          <li>Addiction Counseling</li>
          <li>Relationship and Family Therapy</li>
          <li>Stress Management</li>
          <li>Psychiatric Evaluations and Medication Management</li>
        </ul>
      </section>

      <section>
        <h2>Areas Served in Delhi NCR</h2>
        <p>Dr. Kumar provides his expert services across Delhi NCR, including:</p>
        <ul>
          <li>Shalimar Bagh</li>
          <li>Ashok Vihar</li>
          <li>Patel Nagar</li>
          <li>Adarsh Nagar</li>
          <li>Kirti Nagar</li>
          <li>And surrounding areas in Delhi NCR</li>
        </ul>
      </section>

      <section>
        <h2>Why Choose Dr. Pratik Kumar?</h2>
        <ul>
          <li>Experienced psychologist and psychiatrist with a proven track record</li>
          <li>Evidence-based treatments tailored to individual needs</li>
          <li>Comprehensive care covering both psychological and psychiatric aspects</li>
          <li>Convenient locations across Delhi NCR</li>
          <li>Commitment to patient confidentiality and comfort</li>
        </ul>
      </section>

      <section>
        <h2>Contact Information</h2>
        <p>Main Clinic: Ever Bake Market, Shop No. F1, First Floor, BN Block, Local Shopping Centre, West Shalimar Bagh, New Delhi-88</p>
        <p>Phone: 9023997854 / 9877065403</p>
        <p>Email: docpratikkumar@gmail.com</p>
      </section>

      <section>
        <h2>Schedule Your Consultation</h2>
        <p>Take the first step towards better mental health. Contact Dr. Pratik Kumar today to schedule your consultation at any of his Delhi NCR locations.</p>
      </section>
      </div>
  )
}

export default NCRPage;