import { EducationShowcaseListItemProps} from "../education/education-showcase-list-item";

export const EDUCATION: EducationShowcaseListItemProps[] = [
    {
        title: "DNB Psychiatry",
        organisation: {
           name: "Govt. Mental Hospital, Institute of Mental Health, NBE, New Delhi",
           href: "",
        },
        date: "Jan 2018", 
        location: "New Delhi, India",
        description: "Specialisation in DNB psychaitry"
    }, 
    {
        title: "MBBS",
        organisation: {
           name: "JJM Medical College,Rajiv Gandhi University of Health Sciences, Bangalore",
           href: "",
        },
        date: "Aug 2012", 
        location: "Devengere, Karnataka",
        description: "Bachelor degree in Medicine and Bachelor of Surgery"
    },
    {
        title: "Intermediate,CBSE",
        organisation: {
           name: "DPS, Ranchi",
           href: "",
        },
        date: "Jun 2004", 
        location: "Ranchi, Jharkhand",
        description: "Completed my 12 grade from CBSE board"
    },
    {
        title: "High School, CBSE",
        organisation: {
           name: "B.D. Public School, Patna",
           href: "",
        },
        date: "Jun 2002", 
        location: "Patna, Bihar",
        description: "Completed my high school"
    }
]