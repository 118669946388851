import { ResearchListItemProps } from "../research/research-showcase-list-item";

export const RESEARCH: ResearchListItemProps[] = [
    {
        title: "A prospective comparative study to assess the attitude of nursing students towards psychiatry",
        coauthors: "Kumar P., Singh S., Malik A.",
        year: 2018
    },
    {
        title: "Assessing the comorbidity of depression among the patients of obsessive compulsive disorder",
        coauthors: "Singh S., Kumar P.",
        year: 2019
    },
    {
        title: "Metabolic syndrome with drug olanzapine: a comparison between standard and orally disintegrating tablets",
        coauthors: "Malik A, Dalal D, Dahiya K, Kumar P, Singh S, Kumari A.",
        year: 2019
    },
    {
        title: "Psychiatric advance directives: An understanding",
        coauthors: "Nehra D, Singh S, Kumar P, Purushottam, Malik A, Srivastav V.",
        year: 2019
    },
    {
        title: "Examining the attitude of patients suffering from OCD towards drug adherence",
        coauthors: "Kumar B, Kumar P.",
        year: 2019
    }
]