import { ExperienceShowcaseListItemProps } from "../experience/experience-showcase-list-item";


export const EXPERIENCE: ExperienceShowcaseListItemProps[] = [
    
    {
        title: "Senior Resident",
        organisation: {
            name: "IHBAS",
            href: "https://ihbas.delhi.gov.in/",
        },
        date: "15/01/2020 - 14/01/2023",
        location: "New Delhi, India",
        description: "Involved in teaching PG and NON-PG students. Provided clinical care in OPD, IPD, psychiatry ICU, and emergency settings. Participated in outreach OPDs, SAKSHAM halfway home, Mobile Mental Health Unit, and Tele-Manas services. Worked as Chief SR for over a year, developing clinical, communicative, managerial, and administrative skills."
    },
    {
        title: "Consultant Psychiatrist",
        organisation: {
            name: "NIRMAL CHHAYA, DISHA, PARIVARTAN, SAMARPAN | Various De-addiction and Neuropychiatry Centers",
            href: "",
        },
        date: "2017 - 2019",
        location: "Punjab, India",
        description: "Worked in de-addiction and neuropsychiatry (OPD and IPD) across multiple centers in Punjab: NIRMAL CHHAYA (Tarn Taran), DISHA (Jagraon), PARIVARTAN (Amritsar), and SAMARPAN (Chheharta, Amritsar)."
    }
]